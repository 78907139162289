exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal-privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-refund-policy-js": () => import("./../../../src/pages/legal-refund-policy.js" /* webpackChunkName: "component---src-pages-legal-refund-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal-terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-purchase-transcription-minutes-js": () => import("./../../../src/pages/purchase-transcription-minutes.js" /* webpackChunkName: "component---src-pages-purchase-transcription-minutes-js" */),
  "component---src-pages-session-js": () => import("./../../../src/pages/session.js" /* webpackChunkName: "component---src-pages-session-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-transcription-minutes-purchase-failed-js": () => import("./../../../src/pages/transcription-minutes-purchase-failed.js" /* webpackChunkName: "component---src-pages-transcription-minutes-purchase-failed-js" */),
  "component---src-pages-transcription-minutes-purchase-successful-js": () => import("./../../../src/pages/transcription-minutes-purchase-successful.js" /* webpackChunkName: "component---src-pages-transcription-minutes-purchase-successful-js" */)
}

